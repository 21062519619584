import { createTheme, ThemeOptions } from "@mui/material/styles";

const themeOptions: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Custom border radius
          textTransform: "none", // Disable uppercase text
          padding: "8px 16px", // Custom padding
        },
        contained: {
          boxShadow: "none", // Remove shadow from contained buttons
          "&:hover": {
            boxShadow: "none", // Remove shadow on hover
          },
        },
        outlined: {
          borderWidth: "2px", // Thicker border for outlined buttons
          "&:hover": {
            borderWidth: "2px", // Keep border consistent on hover
          },
        },
      },
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
