import { Post, Prisma } from "@prisma/client";
import axiosInstance from "./axiosInstance";

type PostWithAuthor = Prisma.PostGetPayload<{
  include: {
    author: true;
  };
}>;

// Function to add an email to the /api/interested-emails endpoint
export const addInterestedEmailApi = (
  email: string,
  callback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  axiosInstance
    .post(`/api/interested-emails`, { email })
    .then((res) => {
      callback(res.data); // Return the created email object
    })
    .catch((err) => {
      errorCallback(err.response?.data || err.message); // Handle errors
    });
};

// Function to add an email to the /api/interested-emails endpoint
export const getAllPosts = (
  callback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  axiosInstance
    .get(`/api/posts`)
    .then((res) => {
      callback(res.data); // Return the created email object
    })
    .catch((err) => {
      errorCallback(err.response?.data || err.message); // Handle errors
    });
};

// Function to create a new post
export const createPost = (
  post: Omit<Prisma.PostCreateInput, "author"> & {
    author: Prisma.AuthorCreateInput;
  },
  callback: (response: Post) => void,
  errorCallback: (error: any) => void
) => {
  axiosInstance
    .post<Post>("/api/posts", post)
    .then((res) => {
      callback(res.data); // Handle the created post
    })
    .catch((err) => {
      errorCallback(err.response?.data || err.message); // Handle errors
    });
};

// Function to fetch a single post by slug
export const getPost = (
  slug: string, // Use slug to fetch the post
  callback: (response: PostWithAuthor) => void, // Success callback
  errorCallback: (error: any) => void // Error callback
) => {
  axiosInstance
    .get<PostWithAuthor>(`/api/posts/${slug}`) // Make a GET request using the slug
    .then((res) => {
      callback(res.data); // Pass the fetched post data to the callback
    })
    .catch((err) => {
      errorCallback(err.response?.data || err.message); // Handle errors
    });
};
