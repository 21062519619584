import React, { useContext, useState } from "react";
import ReusableDialog from "../../Dialog";
import {
  Box,
  DialogContent,
  Button,
  Stack,
  Typography,
  TextField,
  DialogActions,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addInterestedEmailApi } from "../../../utils/apicalls";
import { UIContext, UIState } from "../../../providers/UIProvider";

const SignUpReleaseUpdates = () => {
  const [open, setOpen] = useState(false); // Dialog open state
  const [, dispatch] = useContext<UIState | any>(UIContext);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleSubmit = (
    values: { email: string },
    { setSubmitting, resetForm }: any
  ) => {
    console.log("Form submitted with values:", values);

    addInterestedEmailApi(
      values.email,
      (response) => {
        console.log("Email successfully added:", response);
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message:
              "You’ve successfully subscribed to updates and notifications for STR Monitoring. Thank you!",
            severity: "success",
          },
        });
        setSubmitting(false); // Stop submission loading
        resetForm(); // Reset form fields
        setOpen(false); // Close the dialog
      },
      (error) => {
        console.error("Error adding email:", error);
        setSubmitting(false); // Stop submission loading
      }
    );
  };

  return (
    <>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        maxWidth={"md"}
        content={
          <Formik
            initialValues={{ email: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <DialogContent>
                  <Box
                    sx={{
                      maxWidth: 800,
                      width: "100%",
                      backgroundColor: "#fff",
                      p: 4,
                    }}
                  >
                    <Typography variant="h4" textAlign="center" gutterBottom>
                      Sign Up for Updates
                    </Typography>
                    <Typography variant="body1" textAlign="center" gutterBottom>
                      Stay informed about release updates and new features for
                      STR Monitoring.
                    </Typography>
                    <Stack spacing={2}>
                      <Field
                        as={TextField}
                        name="email"
                        label="Email Address"
                        variant="outlined"
                        type="email"
                        fullWidth
                        error={
                          <ErrorMessage name="email">
                            {(msg) => Boolean(msg)}
                          </ErrorMessage>
                        }
                        helperText={
                          <ErrorMessage name="email">
                            {(msg) => msg}
                          </ErrorMessage>
                        }
                      />
                    </Stack>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Stack spacing={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      fullWidth
                      disabled={isSubmitting} // Disable while submitting
                      sx={{ mt: 2 }}
                    >
                      {isSubmitting ? "Submitting..." : "Sign Up"}
                    </Button>
                  </Stack>
                </DialogActions>
              </Form>
            )}
          </Formik>
        }
        buttonVariant="contained"
        buttonText="Sign Up For Updates"
        title="Sign Up For Updates"
        buttonColor="primary"
      />
    </>
  );
};

export default SignUpReleaseUpdates;
