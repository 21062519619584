import { Button, Dialog, DialogTitle, IconButton, Theme } from "@mui/material";
import React, { ReactNode } from "react";

interface ReusableDialogProps {
  buttonColor?: "primary" | "secondary" | "error" | "info" | "success";
  buttonText?: string;
  dialogsx?: any;
  toolTipTitle?: string;
  buttonVariant?: "contained" | "outlined" | "text";
  buttonFullWidth?: boolean;
  buttonStyle?: any;
  content: ReactNode;
  disabled?: boolean;
  isOpen: boolean;
  icon?: ReactNode;
  onChange?: () => void;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  setIsOpen: (state: boolean) => void;
  title: string;
  type?: string;
}

const ReusableDialog: React.FC<ReusableDialogProps> = ({
  buttonColor = "primary",
  buttonText,
  buttonFullWidth = true,
  buttonVariant = "contained",
  buttonStyle = null,
  content,
  dialogsx,
  toolTipTitle,
  disabled,
  icon,
  isOpen,
  maxWidth,
  onChange,
  setIsOpen,
  title,
  type,
}) => {
  const handleClickOpen = () => {
    setIsOpen(true);
    if (onChange) onChange();
  };

  const handleClose = (event: object, reason: string) => {
    setIsOpen(false);
    if (onChange) onChange();
  };

  return (
    <>
      {type !== "icon" ? (
        <span className="dialog-button" title={toolTipTitle ?? buttonText}>
          <Button
            color={buttonColor}
            data-testid="onClick-button"
            disabled={disabled}
            fullWidth={buttonFullWidth}
            onClick={handleClickOpen}
            size="medium"
            sx={buttonStyle}
            variant={buttonVariant}
          >
            {icon ? icon : null}
            {buttonText}
          </Button>
        </span>
      ) : (
        <IconButton data-testid="onClick-button" onClick={handleClickOpen}>
          {icon}
        </IconButton>
      )}
      {isOpen && (
        <Dialog
          sx={dialogsx}
          maxWidth={maxWidth}
          open={isOpen}
          data-testid="dialog-wrapper"
          onClose={handleClose}
          fullWidth
        >
          <DialogTitle
            data-testid={"dialog-title"}
            sx={(theme: Theme) => ({
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
            })}
          >
            {title}
          </DialogTitle>
          {content}
        </Dialog>
      )}
    </>
  );
};

export default ReusableDialog;
