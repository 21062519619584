import React from "react";
import "./App.css";
// import ComingSoon from "./Pages/ComingSoon";

import { ThemeProvider, CssBaseline } from "@mui/material";
import theme from "./theme"; // Import the theme
import SnackbarAlert from "./Components/SnackbarAlert";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Routes } from "react-router";
import Posts from "./Pages/Posts";
import BlogPost from "./Pages/BlogPost"; // Import the new BlogPost component

ReactGA.initialize("G-VH88X78D14"); // Replace with your GA4 Measurement ID
ReactGA.send("pageview"); // Tracks the initial page load

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <div className="App">
        <BrowserRouter>
          <SnackbarAlert />
          <Navbar />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<Posts />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
          </Routes>
          {/* <ComingSoon /> */}
          <Footer />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
