import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import SignUpReleaseUpdates from "../Modals/SignUpReleaseUpdates";

interface SectionProps {
  image: string;
  header: string;
  description: string[]; // Change description to an array for list support
  buttonText: string;
  buttonLink: string;
}

const SplitSection: React.FC<SectionProps> = ({
  image,
  header,
  description,
  buttonText,
  buttonLink,
}) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        gap: 4,
        padding: "4rem 2rem",
        backgroundColor: "#f5faff",
      }}
    >
      <Grid
        container
        spacing={4}
        sx={{
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
          }, // Ensure column layout for medium screens
        }}
      >
        {/* Text Content */}
        <Grid
          size={{ xs: 12, sm: 6, md: 6 }}
          sx={{
            textAlign: { xs: "left", md: "left" },
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              color: "#000",
              marginBottom: "1rem",
            }}
          >
            {header}
          </Typography>

          {/* Render description as a list */}
          <Box>
            {description.map((text, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  color: "#555",
                  lineHeight: "1.8",
                  marginBottom: index < description.length - 1 ? "1rem" : "0",
                }}
              >
                {text}
              </Typography>
            ))}
          </Box>

          <Box justifyContent={"center"}>
            {/* <Link href={buttonLink} underline="none">
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#0066cc",
                  color: "#0066cc",
                  borderRadius: "24px",
                  padding: "0.5rem 1.5rem",
                  "&:hover": {
                    backgroundColor: "#0066cc",
                    color: "#fff",
                  },
                }}
              >
                {buttonText}
              </Button>
            </Link> */}
            <SignUpReleaseUpdates />
          </Box>
        </Grid>

        {/* Image */}
        <Grid
          size={{ xs: 12, sm: 6, md: 5 }}
          sx={{
            textAlign: "center",
          }}
        >
          <Box
            component="img"
            src={image}
            alt="section image"
            sx={{
              width: "100%",
              maxWidth: "800px",
              borderRadius: "12px",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SplitSection;
