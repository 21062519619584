import React from "react";
import { Helmet } from "react-helmet"; // Import Helmet for managing head metadata
import Hero from "../../Components/Hero";
import FeatureSection from "../../Components/FeatureSection";
import SplitSection from "../../Components/SplitSection";

type Props = {};

const IndexPage = (props: Props) => {
  return (
    <>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>
          Short-Term Rental Monitoring | Short-Term Rental Monitoring
        </title>
        <meta
          name="description"
          content="Learn how STR Monitoring helps communities, HOAs, and local governments manage short-term rental activity effectively and protect their neighborhoods."
        />
        <meta
          name="keywords"
          content="short-term rental, STR monitoring, community compliance, HOA, rental activity, local government tools"
        />
        <meta property="og:title" content="Short-Term Rental Monitoring" />
        <meta
          property="og:description"
          content="Empower your community with STR Monitoring tools to maintain compliance, protect neighborhoods, and streamline short-term rental management."
        />
        <meta property="og:image" content="/images/hero.jpg" />
        <meta property="og:url" content="https://strmonitoring.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Short-Term Rental Monitoring" />
        <meta
          name="twitter:description"
          content="Empower your community with STR Monitoring tools to maintain compliance and streamline rental management."
        />
        <meta name="twitter:image" content="/images/hero.jpg" />
        <link rel="canonical" href="https://strmonitoring.com/" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: "Short-Term Rental Monitoring",
            description:
              "Learn how STR Monitoring helps communities, HOAs, and local governments manage short-term rental activity effectively and protect their neighborhoods.",
            url: "https://strmonitoring.com/",
            image: "/images/hero.jpg",
            author: {
              "@type": "Organization",
              name: "STR Monitoring",
            },
            mainEntity: [
              {
                "@type": "Service",
                name: "Custom Email Alerts",
                description:
                  "Stay informed with personalized email notifications whenever new activity is detected in your neighborhood.",
              },
              {
                "@type": "Service",
                name: "Comprehensive Reports",
                description:
                  "Generate detailed reports to streamline your process for identifying short-term rental violations.",
              },
              {
                "@type": "Service",
                name: "Accurate Location Insights",
                description:
                  "Gain unmatched precision with STR Monitoring, pinpointing exact locations and accessing photos of short-term rental listings.",
              },
            ],
          })}
        </script>
      </Helmet>

      {/* Main Content */}
      <div>
        <Hero
          title="Short Term Rental Monitoring"
          subTitle="We're still under development! Be the first to know when we launch by signing up for notifications."
          buttonText="Sign Up for Notifications"
          buttonLink="/sign-up"
          backgroundImage="/images/hero.jpg"
        />

        <FeatureSection
          sectionText="Our Main Features"
          headerText="How We Make a Difference in Your Community"
          subText="Every community has unique needs when it comes to monitoring and managing short-term rentals. Our solutions empower neighborhoods, HOAs, and local governments with tools to maintain compliance and protect their communities."
          cards={[
            {
              image: "/images/email-alert.jpg",
              title: "Custom Email Alerts",
              description:
                "Stay informed with personalized email notifications whenever new activity is detected in your neighborhood. Stay ahead and take action effortlessly.",
            },
            {
              image: "/images/report-creation.jpg",
              title: "Comprehensive Reports",
              description:
                "Easily generate and download detailed reports to streamline your process when identifying short-term rental violations. Empower your decision-making with accurate data.",
            },
            {
              image: "/images/location-finder.jpg",
              title: "Accurate Location Insights",
              description:
                "Gain unmatched precision with STR Monitoring. Pinpoint exact locations and access photos of potential short-term rental listings, giving you the tools to verify with confidence.",
            },
          ]}
        />

        <SplitSection
          image="/images/building-neighbors.png"
          header="Helping Communities Nationwide"
          description={[
            "We partner with neighborhoods, HOAs, and local governments across the country to monitor and manage short-term rental activity effectively.",
            "Our solutions are tailored to meet your community's unique needs.",
            "Ready to take the next step?",
            "• Learn More: Connect with our team for a personalized consultation.",
            "• Explore Our Demo: Sign up today or try out our free demo with mock data to see how it works for your community.",
          ]}
          buttonText="Free Demo Try Now"
          buttonLink="/demo"
        />
      </div>
    </>
  );
};

export default IndexPage;
