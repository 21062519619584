import React, { useReducer, createContext } from "react";
export type UIState = {
  snackbar: {
    show: boolean;
    message: string | React.ReactNode;
    duration?: number;
    action?: React.ReactNode;
    severity: "error" | "warning" | "info" | "success";
    vertical: "top";
    horizontal: "center";
  };
};

const initialState: UIState = {
  snackbar: {
    show: false,
    message: "An Error Happened",
    severity: "error",
    vertical: "top",
    horizontal: "center",
  },
};

export const UIContext: any = createContext(initialState);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "Snackbar":
      return {
        ...state,
        snackbar: action.payload,
      };

    default:
      return state;
  }
};

export const UIContextProvider = (props: any) => {
  const [state, dispatch]: any = useReducer(reducer, initialState);

  return (
    <UIContext.Provider value={[state, dispatch]}>
      {props.children}
    </UIContext.Provider>
  );
};
